@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts";

body {
  @apply font-poppins;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

input:focus, textarea:focus, select:focus {
  outline: none 0;
}
